<template>
  <div class="live-view">
    <div class="content">
      <div class="map-area">
        <hotel-floor/>
      </div>
      <!--      <div class="selectors">-->
      <!--        <span style="font-size: 27px; background-color: white; padding: 0 5px;">-->
      <!--          <select class="selector" v-model.number="selectors.minutes">-->
      <!--            <option disabled value="">Please select bucket</option>-->
      <!--            <option value="5">5 minutes</option>-->
      <!--            <option value="30">30 minutes</option>-->
      <!--            <option value="60">60 minutes</option>-->
      <!--          </select>-->
      <!--        </span>-->
      <!--      </div>-->
      <div class="charts">
        <!--        <div class="selectors-top">-->
        <!--          <select class="selector" v-model.number="selectors.lane_num">-->
        <!--            <option disabled value="">Please select Lane</option>-->
        <!--            <option v-for="ln in checkpoint.lanes" :key="ln.lane_num" :value="ln.lane_num">-->
        <!--              Lane {{ ln.lane_num }}-->
        <!--            </option>-->
        <!--          </select>-->
        <!--        </div>-->
        <div class="graph">
          <!--Graph1-->
          <bar-chart-floor
            minutes=5
            width=600
            height=300
            :update_key="update_key"
          />
        </div>
        <div class="graph">
          <!--Graph2-->
          <chart-day
            width="600"
            height="300"
            chart_type="bar"
            category="area_type"
            :stacked="true"
            :fill="true"
            label="datetime"
            measure="peoplecount_average"
            func="avg"
            filters_global=""
          />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HotelFloor from '@/components/HotelFloor'
import barChartFloor from '@/components/BarChartFloor'
import ChartAnalysisViewHotel from '@/components/ChartAnalysisViewHotel'

export default {
  name: 'LiveView',
  components: {
    HotelFloor,
    barChartFloor,
    chartDay: ChartAnalysisViewHotel
  },
  data () {
    return {
      selectors: {
        minutes: 5
      },
      bucket_start_time_old: 0,
      update_key: 0
    }
  },
  computed: {
    ...mapGetters(['tenant', 'areas'])
  },
  methods: {
    // bindItems () {
    //   this.$store.dispatch('bindTenant')
    //   this.$store.dispatch('bindCheckpoint')
    // },
    // unbindItems () {
    //   this.$store.dispatch('unbindTenant')
    //   this.$store.dispatch('unbindCheckpoint')
    // }
  },
  created () {
    // this.update_lane()
  },
  mounted () {
    // this.update_lane()
  },
  updated () {
  },
  beforeDestroy () {
    // this.$store.dispatch('unbindCheckpoint')
  },
  watch: {
  }
}

</script>

<style scoped>
.live-view {
  width: 100%;
  /*width: 900px;*/
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  /* height: 75vh; */
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /*flex-wrap: wrap;*/
  justify-content: center;
  /*background-color: #fcf488;*/
  padding: 10px;
  /*height: 700px;*/
  /* margin: 3vh 0; */
}

/*@media (max-width: 1200px) {*/
/*  .content {*/
/*    flex-wrap: wrap;*/
/*  }*/
/*}*/

.airport-name {
  /*display: inline-block;*/
  width: 70%;
}

.selectors {
  display: inline-block;
  width: 95%;
  height: 20px;
  border-bottom: 1px solid #2d1d604b;
  text-align: center;
  /*align-content: center;*/
}

.selectors-top {
  display: inline-block;
  width: 95%;
  height: 20px;
  text-align: center;
  margin-bottom: 20px;
  /*align-content: center;*/
}

.selector {
  display: inline;
  align-content: center;
  text-align: center;
}

.grasp-svg {
  width: 50%;
  flex-shrink: 1;
}

.left-area {
  min-width: 500px;
  max-width: 1000px;
  flex-basis: auto; /* default value */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /*background-color: #ffffff;*/
  width: 1000px;
  height: 700px;
  padding: 1px;
  /* justify-content: space-evenly; */
  justify-content: space-around;

}

.right-area {
  min-width: 500px;
  max-width: 1000px;
  flex-basis: auto; /* default value */
  flex-grow: 1;
  /*display: flex;*/
  /*flex-direction: column;*/
  /*justify-content: space-between;*/
  /*background-color: #fcb574;*/
  width: 1000px;
  height: 700px;
  padding: 1px;
}

.kpi-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #2d1d604b;
  height: 10%;
}

/* .kpi-set {
  border-bottom: 1px solid #2d1d604b;
} */

/* .kpi-set-caption {
  font-size: 12px;
  font-weight: normal;
  opacity: 0.25;
} */

.map-area {
}

.graph {
  height: 30%;
  /*background: #1abc9c;*/
  background: white;
  margin-top: 10px;
}

.header h1 {
  font-size: 28px;
  color: #2D1D60;
  margin: 0px;
}

.header p {
  font-size: 15px;
  color: #505050;
  margin: 0px;
}

hr {
  border-top: 2px solid #4ADFAF;
}
</style>
