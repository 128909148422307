<template>
  <div class="chart">
    <canvas id="myChart" ref="myChart" :width="width" :height="height"></canvas>
  </div>
</template>

<script>
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from 'chart.js'
import 'chartjs-adapter-moment'
import { tenantRef } from '@/plugins/firebaseInit'
import { mapGetters } from 'vuex'

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
)

export default {
  name: 'ChartComponent',
  props: {
    width: Number,
    height: Number,
    minutes: Number,
    update_key: Number
  },
  data () {
    return {
      chart_data: {
        bucket_end_time: [],
        passengers: []
      },
      date_from: '2022-02-01',
      date_to: '2022-03-07',
      limit: 500,
      chart_obj: null,
      api_result: null,
      interval_id: null,
      bucket_start_time_old: 0,
      num_of_bars: 50,
      area_type_color: {
        // bar: '#da653d',
        // seating: '#51ad2c',
        // meeting: '#22909d',
        // 'check-in': '#d92424',
        // seating: '#4ADFAF',
        seating: '#2D1D60',
        'check-in': '#DD392A',
        meeting: '#174E8B',
        bar: '#EFA08D'
      }
    }
  },
  computed: {
    ...mapGetters(['tenant', 'areas'])
  },
  methods: {
    bucket_start_time_comp () {
      return this.areas[11].bucket_5_minutes.bucket_start_time.seconds
    },
    checkBucket () {
      if (this.bucket_start_time_old < this.bucket_start_time_comp()) {
        this.bucket_start_time_old = this.bucket_start_time_comp()
        this.getData()
      }
    },
    checkBucketLoop () {
      const that = this
      this.interval_id = setInterval(that.checkBucket, 500)
    },
    getData () {
      const requests = []
      const data = {}
      for (const area of this.areas) {
        data[area.area_id] = []
        const promise = tenantRef.doc('cB5yc9ArUwJXNvtexuS0').collection('area').doc(area.area_id)
          .collection(`bucket_${this.minutes}_minutes`)
          .orderBy('bucket_start_time', 'desc').limit(this.num_of_bars)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              // console.log(doc.id)
              data[area.area_id].push(doc.data())
            })
            return {
              category: area.area_id,
              color_type: area.area_type,
              ...this.formatFirestoreData(data[area.area_id])
            }
          })
          .catch((error) => {
            console.log('Error getting documents: ', error)
            alert('Error getting documents')
          })
        requests.push(promise)
      }
      Promise.all(requests)
        .then(data => {
          console.log('Resolved BQ data from all promises')
          this.chart_data = data
          this.updateChart()
        })
    },
    formatFirestoreData (fsData) {
      const data = {}
      // this.chart_data.bucket_end_time = fsData.map((row) => this.timeString(row.bucket_end_time))
      data.label = fsData.map((row) => row.bucket_end_time.toDate())
      data.value = fsData.map((row) => row.avg_peoplecount_average)
      return data
    },
    updateChart () {
      this.chart_obj.data.datasets = []
      for (const catData of this.chart_data) {
        if (catData && catData.label.length > 0) {
          this.chart_obj.data.labels = catData.label.reverse()
          this.chart_obj.data.datasets.push(
            {
              label: `${catData.color_type} ${catData.category}`,
              data: catData.value.reverse(),
              borderColor: '#000000',
              fill: true,
              backgroundColor: this.area_type_color[catData.color_type],
              cubicInterpolationMode: 'monotone',
              tension: 0.4
            }
          )
        }
      }
      this.chart_obj.update()
    }
    // timeString (ts) {
    //   const options = {
    //     hour: 'numeric',
    //     minute: '2-digit'
    //     // second: '2-digit'
    //   }
    //   const date = ts.toDate()
    //   return date.toLocaleTimeString('nl-NL', options)
    // }
  },
  created () {
    // this.$store.dispatch('bindPAXScreening', this.lane_id)
  },
  mounted () {
    // const ctx = document.getElementById('myChart')
    const ctx = this.$refs.myChart
    this.chart_obj = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: [],
        datasets: []
      },
      options: {
        scales: {
          y: {
            display: true,
            stacked: true,
            title: {
              display: true,
              text: 'Occupancy',
              color: '#807e8b',
              font: {
                // family: 'Times',
                size: 12,
                style: 'normal',
                lineHeight: 1.2
              },
              padding: {
                top: 10,
                left: 0,
                right: 0,
                bottom: 0
              }
            }
          },
          x: {
            stacked: true,
            grid: {
              display: false
            },
            type: 'time',
            display: true,
            title: {
              display: false,
              text: 'Date'
            },
            ticks: {
              autoSkip: false,
              maxRotation: 0,
              major: {
                enabled: true
              },
              // color: function(context) {
              //   return context.tick && context.tick.major ? '#FF0000' : 'rgba(0,0,0,0.1)';
              // },
              font: function (context) {
                if (context.tick && context.tick.major) {
                  return {
                    weight: 'bold'
                  }
                }
              }
            }
          }
        },
        animation: {
          duration: 200
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: 'bottom'
          },
          title: {
            display: false,
            text: this.title,
            position: 'top',
            align: 'start',
            color: '#2D1D60',
            font: {
              // family: 'Times',
              size: 16,
              style: 'normal',
              lineHeight: 1.2
            }
          }
        }
      }
    })
    // initializes data for first time
    this.checkBucketLoop()
  },
  beforeDestroy () {
    this.chart_obj.destroy()
    clearInterval(this.interval_id)
    // this.$store.dispatch('unbindPAXScreening')
  },
  watch: {
    update_key: function () {
      this.getData()
    },
    minutes: function () {
      this.getData()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.chart {
  margin: 0;
  height: 100%;
  width: 100%;
}

canvas {
  margin: 0;
}
</style>
