<template>
  <div class="checkpoint">
    <svg id="svg" xmlns="http://www.w3.org/2000/svg" :viewBox="viewBox">
      <defs>
      </defs>
      <image :href="mySVG" :width="width" :height="height"/>
      <rect class="rect" :width="width" :height="height"></rect>
      <g transform="translate(200,100)">
        <text font-size="50">{{ totalCountAverage }}</text>
        <text y="30" font-size="20">{{ totalCountInstant }} (instant)</text>
      </g>
      <g v-for="area in areas" :key="area.area_id"
         :transform="`translate(${area.x},${area.y})`"
      >
        <rect x="0" y="0" class="area" :class="area.area_type" :width="area.width" :height="area.height"/>
        <text x="2" y="20" class="area-occupancy">{{ area.bucket_5_minutes.peoplecount_average }}
          ({{ area.bucket_5_minutes.peoplecount_instant }})
        </text>
        <text x="2" y="30" class="area-text">{{area.area_type}}</text>
        <text x="2" y="37" class="area-text">{{area.area_id}}</text>
      </g>
    </svg>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'HotelFloor',
  components: {},
  props: {},
  data () {
    return {
      height: 320,
      width: 440,
      margin: 5,
      mySVG: require('@/assets/floor_plan_crop.png'),
      multiplier: 1.07,
      area_type_color: {
        // bar: '#9b5d48',
        // 'seating area': '#506c44',
        // 'meeting room': '#4a4f96',
        // 'check-in': '#961212',
        // 'seating': '#4ADFAF',
        'seating area': '#2D1D60',
        'check-in': '#DD392A',
        'meeting room': '#174E8B',
        bar: '#EFA08D'
      }
    }
  },
  methods: {
    bindItems () {
      this.$store.dispatch('bindAreas')
    },
    unbindItems () {
      this.$store.dispatch('unbindAreas')
    }
  },
  computed: {
    totalCountInstant () {
      return this.areas.reduce(function (a, b) {
        return a + (b.bucket_5_minutes.peoplecount_instant !== undefined ? b.bucket_5_minutes.peoplecount_instant : 0)
      }, 0)
    },
    totalCountAverage () {
      return this.areas.reduce(function (a, b) {
        return a + (b.bucket_5_minutes.peoplecount_average !== undefined ? b.bucket_5_minutes.peoplecount_average : 0)
      }, 0)
    },
    viewBox () {
      // return `0 0 ${this.width + this.margin * 2} ${this.height + this.margin * 2}`
      return `0 0 ${this.width} ${this.height}`
    },
    ...mapGetters(['cpNum', 'checkpoint', 'positions', 'areas'])
  },
  beforeCreate () {
  },
  created () {
  },
  mounted () {
    this.bindItems()
  },
  beforeDestroy () {
    this.unbindItems()
  },
  watch: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.checkpoint {
  align-content: center;
  align-items: center;
}

#svg {
  align-self: center;
  /*stroke-width: 2;*/
  /*stroke: #2D1D60;*/
}

.rect {
  fill: none;
  stroke: #323232;
  stroke-width: 1;
}

.area-occupancy {
  fill: black;
  text-anchor: start;
}

.area {
  fill: rgba(121, 136, 225, 0.48);
  stroke: #777777;
  stroke-width: 0.5;
  stroke-miterlimit: 10;
}

.area-text {
  font-size: 7px;
  fill: black;
}

.check-in {
  /*fill: rgba(248, 49, 49, 0.7);*/
  fill: rgba(221, 57, 42, 0.7);
}

.meeting {
  /*fill: rgba(53, 186, 204, 0.7);*/
  fill: rgba(23, 78, 139, 0.7);
}

.bar {
  /*fill: rgba(218, 104, 65, 0.7);*/
  fill: rgba(239, 160, 141, 0.7);
}

.seating {
  /*fill: rgba(121, 252, 65, 0.7);*/
  fill: rgba(45, 29, 96, 0.7);
}

</style>
